import React from 'react'

import { Grid } from '@material-ui/core'

import Footer from '../../Footer'

import useStyles from './styles'

const NonAuthLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <div className={classes.content}>
        <Grid
          className={classes.wrapper}
          container
          justify="center"
          alignItems="center"
        >
          {children}
        </Grid>
        <Footer />
      </div>
    </div>
  )
}

export default NonAuthLayout
