import React from 'react'

import { Typography } from '@material-ui/core'

import useStyles from './styles'

const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <ul className={classes.ul}>
            <li className={classes.li}>Termos</li>
            <li className={classes.li}>Privacidade</li>
            <li className={classes.li}>Suporte</li>
            <li className={classes.li}>Contato</li>
          </ul>
        </div>
        <Typography className={classes.brand}>2020 Learn49</Typography>
      </div>
    </footer>
  )
}

export default Footer
