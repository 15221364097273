import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '32px'
  },
  forgotPass: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#546E7A',
    textDecoration: 'none'
  },
  loading: {
    color: '#fff'
  }
}))

export default useStyles
