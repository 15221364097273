import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '16px 0'
      }
    },
    wrapper: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 624,
        width: '100%'
      }
    },
    ul: {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      marginRight: -106,
      [theme.breakpoints.down('md')]: {
        marginRight: 0
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'space-between'
      }
    },
    li: {
      padding: '0 20px',
      // paddingRight: '20px',
      color: '#546E7A',
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    brand: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#546E7A'
    }
  })
)

export default useStyles
