import { makeStyles } from '@material-ui/core/styles'

import login from '../../../assets/img-login.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'unset',
    height: '100vh',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start'
    }
  },
  background: {
    background: `url(${login}) no-repeat center`,
    backgroundSize: 'cover',
    maxWidth: '684px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '0 10px',
    flexGrow: 'unset',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: '100%'
    }
  },
  wrapper: {
    flex: 1
  }
}))

export default useStyles
