import React, { useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useFormik } from 'formik'
import Link from 'next/link'
import Router from 'next/router'
import * as Yup from 'yup'

import { Button, Grid, CircularProgress, Typography } from '@material-ui/core'

import NonAuthLayout from '../../_layouts/NonAuthLayout'
import TextField from '../../TextField'

import { useAccount } from '../../../lib/AccountContext'
import { useSnackbar } from '../../../lib/SnackbarContext'

import useStyles from './styles'

const AUTH_USER = gql`
  mutation($accountId: String!, $UserInput: UserInput!) {
    auth(accountId: $accountId, input: $UserInput) {
      user {
        id
        firstName
        lastName
        email
        profilePicture
        role
      }
      token
    }
  }
`

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  passwd: Yup.string().required('Campo obrigatório')
})

const SignIn = () => {
  const classes = useStyles()
  const { handleOpenSnackbar } = useSnackbar()
  const { id: accountId } = useAccount()
  const [auth] = useMutation(AUTH_USER, {
    onCompleted(payload) {
      localStorage.setItem('learn49-token', payload.auth.token)
      localStorage.setItem('learn49-user', JSON.stringify(payload.auth.user))
      Router.push('/app')
    }
  })
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    errors,
    touched,
    handleBlur
  } = useFormik({
    initialValues: {
      email: '',
      passwd: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await auth({
          variables: { accountId, UserInput: values }
        })
      } catch (error) {
        handleOpenSnackbar({
          severity: 'error',
          message: 'Credenciais inválidas.'
        })
      }
    }
  })

  useEffect(() => {
    const token = localStorage.getItem('learn49-token')
    if (token) {
      Router.push('/app')
    }
  }, [])

  return (
    <NonAuthLayout>
      <form onSubmit={handleSubmit} style={{ maxWidth: 624, width: '100%' }}>
        <img
          src={require('../../../assets/logo-devpleno-black.svg')}
          alt="Logo devpleno"
          style={{ marginBottom: 16 }}
        />
        <Typography variant="h1" className={classes.title}>
          Faça seu login ou cadastre-se
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="E-mail"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.email && touched.email ? errors.email : ''}
              error={errors.email && touched.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="passwd"
              label="Senha"
              type="password"
              id="password"
              value={values.passwd}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.passwd && touched.passwd ? errors.passwd : ''}
              error={errors.passwd && touched.passwd}
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Link href="/forgot-password" passHref>
              <a className={classes.forgotPass}>Esqueceu sua senha?</a>
            </Link>
          </Grid>
          <Grid item container spacing={2} style={{ flexDirection: 'row' }}>
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disableElevation
              >
                {isSubmitting ? (
                  <CircularProgress className={classes.loading} size={24} />
                ) : (
                  'Entrar'
                )}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => Router.push('/signup')}
              >
                Criar Conta
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </NonAuthLayout>
  )
}

export default SignIn
